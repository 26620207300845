<template>
  <section id="companion-container">
    <h4 class="title">Acompanhante</h4>
    <hr />
    <b-row class="w-100 text-center" align-v="center">
      <b-col>
        <Avatar
          :src="companionPicture"
          size="90px"
          margin="0 auto"
          :hasCamera="true"
          @open-camera="handleChangeImg"
          key="pictureKey"
        />
        <b-form-input
          autocomplete="off"
          @input="changeInput($event, 'name')"
          v-model="companion.name"
          placeholder="Nome do acompanhante"
          class="mt-2"
        />
      </b-col>
    </b-row>
    <hr />
    <b-row class="w-100 text-center mt-2" align-v="center">
      <b-col>
        <b-form-input
          autocomplete="off"
          @input="changeInput($event, 'cpf')"
          v-model="companion.cpf"
          placeholder="CPF do acompanhante"
          v-mask="'###.###.###-##'"
        />
      </b-col>
    </b-row>

    <ProfilePictureModal
      :personId="patientId"
      @change-croped-image="changeCropedImage"
    />
  </section>
</template>

<script>
import debounce from 'lodash/debounce'
import companionMixin from '@/mixins/SurgeryCenter/companionMixin'

export default {
  name: 'CompanionContainer',
  props: {
    surgeryInformationId: String,
    patientId: String
  },
  components: {
    Avatar: () => import('@/components/General/Avatar'),
    ProfilePictureModal: () => import('@/components/Patient/ProfilePictureModal')
  },
  created() {
    this.changeInput = debounce(this.changeInput, 1000)
  },
  data: () => ({
    companion: {
      id: null,
      name: '',
      cpf: '',
      picture: null,
      pictureKey: 0,
    }
  }),
  mixins: [
    companionMixin('canEdit', 'surgeryInformationId', 'companion', 'patientId')
  ],
  computed: {
    canSave() {
      return this.companion.name && this.surgeryInformationId
    },
    surgeryInformatationAndPatientId() {
      return `${this.surgeryInformationId} | ${this.patientId}`
    },
    companionPicture() {
      return typeof this.companion.picture === 'string'
        ? this.companion.picture
        : null
    }
  },
  methods: {
    handleChangeImg() {
      if (this.companion.id) 
        return this.$bvModal.show('profile-picture-modal')
      
      this.$toast.warning('É necessário salvar o acompanhante antes de adicionar uma foto')
    },
    async changeInput(value, type) {
      if (type === 'cpf' && !this.companion.name) return
      if (type === 'cpf' && !this.cpf.isValid(value)) {
        this.$toast.warning('O CPF informado é inválido')
        return
      }
      this.saveCompanion()
    },
    async changeCropedImage(image) {
      this.companion.picture = image
      this.pictureKey += 1
      if (this.companion.id) await this.updateSurgeryCompanion()
    }
  },
  watch: {
    surgeryInformationId: {
      async handler() {
        await this.findCompanion()
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
#companion-container {
  background-color: var(--neutral-000);
  border-radius: 4px;
  padding: 16px;
  margin: 16px 0;

  h4 {
    font-size: 1.1rem;
  }
}
</style>
